import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'
import SubModal from '../../components/Modal/SubModal'
import * as Yup from 'yup'
import CallServiceTable from '../../components/Tables/CallServiceTable'
import Texto from '../../components/Text/Texto'
import Button from '../../components/Buttons/Button'
import { Request } from '../../hooks/Request'
import { useAuth } from '../../contexts/AuthContext'
import 'react-tippy/dist/tippy.css'
import ModalFullScreen from '../../components/Modal/ModalFullScreen'
const Roles = () => {
  const { getuser } = useAuth()

  const [infoRols, setInfoRols] = useState()
  // const [infoMenus, setInfoMenus] = useState()
  const [rols, setRols] = useState()
  const [remov, setRemov] = useState(false)
  const [createMenuForm, setCreateForm] = useState(false)
  const [reload, setreload] = useState(false)
  const [btnStep, setBtnStep] = useState(false)

  function editStep(values) {
    setRols(values)
    setInfoRols(values)
    setBtnStep(true)
  }
  function deleteStep(values) {
    setRols(values)
    setInfoRols(values)
    setRemov(true)
  }
  // function getMenus(data) {
  //   setInfoRols(data)
  // }

  const { loading, data, call } = Request({
    urlApi: infoRols ? `/rol/${infoRols?.id}/menu` : null,
  })

  useEffect(() => {
    call()
  }, [infoRols])

  return (
    <>
      <CallServiceTable
        reload={reload}
        component={() => {
          return (
            <Button onClick={() => setCreateForm(true)}>
              {/* <Button onClick={() => handleNewUser(reload)}> */}
              Crear rol <i className="fa-solid fa-plus"></i>
            </Button>
          )
        }}
        urlApi="/rol"
        header={[
          {
            name: 'label',
            label: 'Roles',
            filter: false,
          },
          {
            name: 'edit',
            type: 'action',
            label: 'Editar',
            actions: [
              {
                label: 'Editar',
                icon: 'fas fa-edit',
                action: editStep,
                color: 'text-[#1d4ed8]',
              },
            ],
          },
          {
            name: 'delete',
            type: 'action',
            label: 'Eliminar',
            actions: [
              {
                label: 'Eliminar',
                icon: 'fas fa-trash',
                action: deleteStep,
                color: 'text-red-500',
              },
            ],
          },
        ]}
        statusPaination={false}
        showTable={true}
      />
      <SubModal setactivateForm={setCreateForm} activateForm={createMenuForm}>
        <div className="bg-slate-200 px-[30px]">
          <FormDefault
            tittle="Crear un nuevo rol"
            fields={[
              {
                label: 'Etiqueta',
                name: 'Label',
                placeholder: 'Introduzca el nombre del menu',
                type: 'text',
                typeInput: 'input',
              },
              {
                label: 'Nombre de rol',
                name: 'Name',
                placeholder: 'Introduzca el rol',
                type: 'text',
                typeInput: 'input',
              },
              {
                label: 'Campo de referencia para asignación',
                name: 'FieldReference',
                placeholder: '',
                type: 'text',
                typeInput: 'input',
              },
              {
                label: 'Menús que tendrá el rol:',
                name: 'MenuList',
                type: 'checkbox',
                typeInput: 'checkboxes',
                value: 'name',
                urlApi: '/menu',
              },
            ]}
            initialValues={{
              Name: '',
              Label: '',
              FieldReference: '',
              MenuList: [],
            }}
            validationSchema={Yup.object().shape({
              Name: Yup.string().required('Este campo es obligatorio'),
              Label: Yup.string().required('Este campo es obligatorio'),
            })}
            onSubmit={async (values, resetForm) => {
              await requestAuth('post', '/rol', {
                ...values,
                MenuList: values.MenuList.map((item) => ({
                  Name: item,
                })),
              })
                .then(() => {
                  toast.success('Envio exitoso')
                  setreload(true)
                  setCreateForm(false)
                  getuser()
                  resetForm()
                })
                .catch((response) => {
                  toast.error(response.response.data)
                })
            }}
            buttonName="Enviar"
          />
        </div>
      </SubModal>

      <SubModal setactivateForm={setBtnStep} activateForm={btnStep}>
        <FormDefault
          tittle="Editar campos del rol"
          fields={[
            {
              label: 'Etiqueta',
              name: 'Name',
              placeholder: 'Introduzca el nombre del menu',
              type: 'text',
              typeInput: 'input',
            },
            {
              label: 'Nombre de rol',
              name: 'Label',
              placeholder: '',
              type: 'text',
              typeInput: 'input',
            },
            {
              label: 'Campo de referencia para asignación',
              name: 'FieldReference',
              placeholder: '',
              type: 'text',
              typeInput: 'input',
            },
            {
              label: 'Lista de menus',
              name: 'MenuList',
              type: 'checkbox',
              typeInput: 'checkboxes',
              value: 'name',
              urlApi: '/menu',
            },
          ]}
          initialValues={{
            Name: infoRols?.name || '',
            Label: infoRols?.label || '',
            Id: infoRols?.id || '',
            FieldReference: infoRols?.fieldReference || '',
            MenuList: data?.menuList?.map((menu) => '' + menu.name) || [],
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required('Este campo es obligatorio'),
            Label: Yup.string().required('Este campo es obligatorio'),
          })}
          onSubmit={async (values, resetForm) => {
            await requestAuth('put', `/rol`, {
              ...values,
              MenuList: values.MenuList.map((item) => ({
                Name: item,
              })),
            })
              .then(() => {
                setreload(!reload)
                setBtnStep(false)
                toast.success('Envio exitoso')
                getuser()
                resetForm()
              })
              .catch(() => {
                toast.error('Se produjo un error al enviar')
              })
          }}
          buttonName="Enviar"
        />
      </SubModal>

      <ModalFullScreen setactivateForm={setRemov} activateForm={remov}>
        <div className="flex flex-col justify-center py-[30px]">
          <Texto className="mx-auto text-[20px] my-[20px] font-bold">
            Se eliminara el rol de {infoRols?.label}
          </Texto>
          <Button
            type="submit"
            className="bg-red-500 text-white flex items-center justify-center my-[20px]"
            onClick={() => {
              requestAuth('delete', '/rol', { Id: infoRols?.id }).then(() => {
                setRemov(false)
                setreload(!reload)
                toast.success('Compañia eliminada')
              })
            }}
          >
            Eliminar
          </Button>
        </div>
      </ModalFullScreen>
    </>
  )
}
const exitEsc = (setClose) => {
  document.addEventListener('keydown', (event) => {
    if (event.which === 27 && event.keyCode === 27) {
      setClose(false)
    }
  })
}
export default Roles
