import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { Request } from "../../hooks/Request";
import { useDialog } from "../../contexts/DialogContext";
import { Button } from "../../components/Buttons";
import { Circle } from "../../components/Animation";
import { validateArray } from "../../utils/validate";
import { H2, H3, Texto } from "../../components/Text";
import { fieldCategoryFormInput, fileFormInput } from "../../utils/fieldForm";
import SubModal from "../../components/Modal/SubModal";
import FormDefault from "../../components/Forms/FormDefault";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { requestAuth } from "../../components/services/RequestService";
import DragList from "../../components/Drags/DragList";
import { DialogConfirmation } from "../../components/Modal";

const ListFields = (props) => {
  const { step, adminStatus = true } = props;
  const [modalView, setModalView] = useState({
    status: false,
    type: "",
    field: null,
  });

  const { data, loading, call } = Request({
    urlApi: `/processstep/${step.id}`,
  });

  const typeText = {
    text: "Texto",
    number: "Número",
    date: "Fecha",
    "datetime-local": "Fecha y hora",
    selectGet: "Lista desplegable",
    selectNewOption: "Lista desplegable con adición",
    file: "Archivo",
    fileStatus: "Archivo con estado",
    requestSerialNumber: "Solicitar número correlativo",
    controlField: "Campo de control",
    IncidentField: "Campo de incidencia",
    fileRead: "Archivo para la lectura",
    fileExcel: "Generar excel",
    checkbox: "Casilla de verificación",
    textArea: "Caja de texto multi-línea",
    subData: "Campo con valor multiple",
  };

  function handleEditField(type, field) {
    setModalView({
      status: true,
      type: type,
      field: field,
    });
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <H2 className="mb-2">{data.name}</H2>
        {adminStatus && (
          <div className="flex gap-2">
            <Button
              className="py-1.5 px-2 text-white"
              content="Crear nuevo campo"
              onClick={() => handleEditField("create", null)}
            >
              <i className="fas fa-plus"></i>
            </Button>
            <Button
              className="py-1 px-2 text-white"
              content="Añadir un campo ya existente"
              onClick={() => handleEditField("select", null)}
            >
              <i className="fas fa-stream"></i>
            </Button>
            <Button
              className="py-1 px-2 text-white"
              content="Ordenar los campos"
              onClick={() => handleEditField("order", null)}
            >
              <i className="fas fa-sort-numeric-up"></i>
            </Button>
          </div>
        )}
      </div>
      {validateArray(data.fields) ? (
        data.fields.map((field, index) => (
          <div
            className="bg-slate-300 rounded-md mb-2 py-1 text-center flex items-center"
            key={field.id}
          >
            <div className={adminStatus ? "w-1/2" : "w-full"}>
              <Texto className="font-semibold">
                <span className="text-blue-600">{index + 1}.</span>{" "}
                {field?.label}
              </Texto>
              <Texto
                className={"text-green-700 font-normal text-xs"}
              >
                {field?.name}
              </Texto>
              <Texto
                className={field?.validate ? "text-red-600 font-semibold" : ""}
              >
                {field?.validate ? "Campo requerido" : "No requerido"}
              </Texto>
              <Texto>{typeText[field?.type]}</Texto>
            </div>
            {adminStatus && (
              <div className="flex items-center justify-center gap-2 w-1/2">
                <Button
                  onClick={() => handleEditField("edit", field)}
                  content="Editar campo"
                  className="py-2 text-white"
                >
                  <i className="fas fa-edit"></i>
                </Button>
                {field?.type === "fileStatus" && (
                  <Button
                    onClick={() => handleEditField("addCategory", field)}
                    content="Asignar categorías"
                    className="py-2 text-white bg-blue-400"
                  >
                    <i className="fas fa-list-ol"></i>
                  </Button>
                )}
                <Button
                  content="Eliminar campo"
                  onClick={() => {
                    requestAuth(
                      "put",
                      `field/deletefieldinprocessStep/${field?.id}/processstepid/${data?.id}`
                    )
                      .then(() => {
                        call();
                        toast.success("Campo eliminado");
                      })
                      .catch(() => {
                        toast.error("Se produjo un error al enviar");
                      });
                  }}
                  className="py-2 text-white bg-red-500"
                >
                  <i className="fas fa-trash-alt"></i>
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <H3>No se tiene campos adicionados</H3>
      )}
      <ListButtonStep adminStatus={adminStatus} stepId={step.id} />
      <SubModal
        onClose={() => setModalView({ status: false, type: "", field: null })}
        activateForm={modalView.status}
      >
        {modalView.type === "edit" ? (
          <FormEditField
            field={modalView.field}
            stepId={step.id}
            call={call}
            onClose={() =>
              setModalView({ status: false, type: "", field: null })
            }
          />
        ) : modalView.type === "create" ? (
          <FormCreateField
            call={call}
            stepId={step.id}
            onClose={() =>
              setModalView({ status: false, type: "", field: null })
            }
          />
        ) : modalView.type === "select" ? (
          <FormSelectField
            call={call}
            stepId={step.id}
            onClose={() =>
              setModalView({ status: false, type: "", field: null })
            }
          />
        ) : modalView.type === "order" ? (
          <FormOrderField
            fields={data.fields}
            orderFieldsActiveorderFieldsActive
            call={call}
            onClose={() =>
              setModalView({ status: false, type: "", field: null })
            }
          />
        ) : modalView.type === "addCategory" ? (
          <FormAddCategoryField
            field={modalView.field}
            call={call}
            stepId={step.id}
            onClose={() =>
              setModalView({ status: false, type: "", field: null })
            }
          />
        ) : null}
      </SubModal>
    </div>
  );
};

const FormOrderField = (props) => {
  const { fields, call, onClose } = props;

  return (
    <DragList
      tittle="Ordenar campos"
      orderFieldsActive={true}
      data={fields}
      onSaveChange={(fields) => {
        const newFields = fields?.map((field, index) => ({
          Id: field.fieldProcessStepId,
          order: index + 1,
        }));
        requestAuth("put", "field/order", newFields)
          .then(() => {
            call();
            onClose();
            toast.success("Cambio de orden exitoso");
          })
          .catch(() => {
            toast.error("Se produjo un error al realizar los cambios");
          });
      }}
    />
  );
};

const FormSelectField = (props) => {
  const { stepId, call, onClose } = props;
  const [loading, setLoading] = useState(false);

  function handleSelect(values) {
    setLoading(true);
    requestAuth("post", `/field/fieldId/${values?.id}/processStepId/${stepId}`)
      .then(() => {
        call();
        setLoading(true);
        onClose();
        toast.success("Campo adicionado");
      })
      .catch((error) => {
        setLoading(true);
        toast.error(
          error.response.data ? error.response.data : "No se pudo adicionar"
        );
      });
  }

  if (loading) {
    return <Circle />;
  }
  return (
    <div>
      <H2>LISTA PARA ADICIONAR CAMPOS EXISTENTES</H2>
      <CallServiceTable
        urlApi="/field"
        header={[
          {
            name: "label",
            label: "Nombre del campo",
            filter: true,
          },
          //   {
          //     name: 'type',
          //     label: 'Tipo de campo',
          //     filter: false,
          //     type: 'translate',
          //     translate: {
          //       text: 'Texto',
          //       file: 'Archivo',
          //       fileRead: 'Archivo',
          //       number: 'Numero',
          //       selectGet: 'Lista desplegable',
          //       checkbox: 'Casilla de verificación',
          //       textArea: 'Caja de texto multi-línea',
          //       date: 'Fecha',
          //     },
          //   },
          {
            name: "edit",
            type: "action",
            label: "Añadir",
            actions: [
              {
                label: "Añadir campo",
                icon: "fas fa-circle-plus",
                action: handleSelect,
                color: "text-[#1d4ed8]",
              },
            ],
          },
        ]}
        statusPaination={true}
        showTable={false}
      />
    </div>
  );
};

const FormCreateField = (props) => {
  const { stepId, call, onClose } = props;
  return (
    <FormDefault
      tittle="Crear un nuevo campo"
      fields={fileFormInput}
      initialValues={{
        Label: "",
        ProcessStepIdList: "",
        Type: "text",
        Url: "",
        Validate: false,
        TableDisplay: false,
        ClientDisplay: false,
        Unique: false,
        AutoGenerated: false,
      }}
      validationSchema={Yup.object().shape({
        Label: Yup.string().required("Este campo es obligatorio"),
        Type: Yup.string().required("Este campo es obligatorio"),
        Url: Yup.string()
          .when("Type", {
            is: "fileRead",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "selectGet",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "fileExcel",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subItemsConta",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subData",
            then: Yup.string().required("Campo requerido."),
          }),
      })}
      onSubmit={async (values, resetForm) => {
        await requestAuth("post", "field", {
          ...values,
          ProcessStepIdList: [stepId],
        })
          .then(() => {
            call();
            onClose();
            toast.success("Nuevo campo creado");
          })
          .catch(() => {
            toast.error("El campo ya existe");
          });
      }}
      buttonName="Enviar"
    />
  );
};

const FormEditField = ({ field, stepId, call, onClose }) => {
  return (
    <FormDefault
      tittle="Editar datos del campos"
      fields={fileFormInput}
      initialValues={{
        id: field?.id || "",
        Initial: field?.initial || "",
        Label: field?.label || "",
        Name: field?.name || "",
        Type: field?.type || "text",
        Url: field?.url || "",
        Color: field?.color || "",
        Validate: field?.validate || false,
        TableDisplay: field?.tableDisplay,
        Unique: field?.unique || false,
        // ClientDisplay: field?.clientDisplay || false,
        // AllowModification: field?.allowModification || false,
        Format: field?.format || "",
        AutoGenerated: field?.autoGenerated || false,
        lockEdition: field?.lockEdition || false,
      }}
      validationSchema={Yup.object().shape({
        Label: Yup.string().required("Este campo es obligatorio"),
        Type: Yup.string().required("Este campo es obligatorio"),
        Url: Yup.string()
          .when("Type", {
            is: "fileRead",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "selectGet",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "fileExcel",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subItemsConta",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subData",
            then: Yup.string().required("Campo requerido."),
          }),
      })}
      onSubmit={async (values, resetForm) => {
        await requestAuth("put", `field/${values.id}/processStepId/${stepId}`, {
          ...values,
          dropdownListId: values?.FatherDropdown
            ? values.FatherDropdown.id
            : null,
        })
          .then(() => {
            toast.success("Campo editado correctamente!");
            call();
            onClose();
          })
          .catch(() => {
            toast.error("Se ha producido un error al enviar");
          });
      }}
      buttonName="Enviar"
    />
  );
};

const FormAddCategoryField = ({ field, stepId, call, onClose }) => {
  const {
    data: generalData,
    loading: loadingGeneralCategories,
    call: callGeneralCategories,
  } = Request({
    urlApi: `/field/${field.id}/categories`,
  });
  const {
    data,
    loading: loadingCategories,
    call: callCategories,
  } = Request({
    urlApi: `/field/${field.id}/categories`,
  });
  return (
    loadingGeneralCategories && (
      <>
        <FormDefault
          tittle={
            <>
              <span className="text-green-600">{field?.label}</span>
              <br />
              Asignación de categoría y validación
            </>
          }
          fields={fieldCategoryFormInput}
          initialValues={{
            fieldId: field?.id || "",
            Category: "",
            Validation: false,
            Status: "",
          }}
          validationSchema={Yup.object().shape({
            Category: Yup.string().required("Este campo es obligatorio"),
            Status: Yup.string().required("Este campo es obligatorio"),
          })}
          onSubmit={async (values, resetForm, initialValues) => {
            await requestAuth("post", `field/category`, values)
              .then(() => {
                resetForm({ ...initialValues });
                toast.success("Parámetro adicinado correctamente!");
                callCategories();
                callGeneralCategories();
                // call()
                // onClose()
              })
              .catch(() => {
                toast.error("Se ha producido un error al enviar");
              });
          }}
          buttonName="Enviar"
        />
        <br />
        {validateArray(data) ? (
          data.map((fieldParam) => (
            <div
              className="bg-slate-300 mb-[10px] p-[10px] sm:p-[20px] text-center flex items-center"
              key={fieldParam.id}
            >
              <div className="w-1/2">
                <Texto className="font-semibold">{fieldParam?.category}</Texto>
                <Texto>{fieldParam?.status}</Texto>
                <Texto
                  className={
                    fieldParam?.validation ? "text-red-500 font-semibold" : ""
                  }
                >
                  {fieldParam?.validation
                    ? "verificación activada"
                    : "no se verifica"}
                </Texto>
              </div>
              <div className="flex items-center justify-center gap-2 w-1/2">
                {/* <Button
                onClick={() => handleEditField('edit', field)}
                content="Editar campo"
                className="py-2 text-white"
              >
                <i className="fas fa-edit"></i>
              </Button> */}
                <Button
                  content={
                    fieldParam.validation
                      ? "Deshabilitar verificación"
                      : "Habilitar verificación"
                  }
                  onClick={() => {
                    requestAuth(
                      "put",
                      `field/fieldParam/${fieldParam?.id}/on-off`
                    )
                      .then(() => {
                        callCategories();
                        toast.success("Campo eliminado");
                      })
                      .catch(() => {
                        toast.error("Se produjo un error al enviar");
                      });
                  }}
                  className={`py-2 text-white ${fieldParam.validation ? "bg-blue-500" : "bg-neutral-400"
                    }`}
                >
                  <i
                    className={`fas fa-${fieldParam.validation ? "toggle-off" : "toggle-on"
                      }`}
                  ></i>
                </Button>
                <Button
                  content="Eliminar campo"
                  onClick={() => {
                    requestAuth("delete", `field/fieldParam/${fieldParam?.id}`)
                      .then(() => {
                        callCategories();
                        toast.success("Campo eliminado");
                      })
                      .catch(() => {
                        toast.error("Se produjo un error al enviar");
                      });
                  }}
                  className="py-2 text-white bg-red-400"
                >
                  <i className="fas fa-trash-alt"></i>
                </Button>
              </div>
            </div>
          ))
        ) : (
          <H3>No se tiene campos adicionados</H3>
        )}
      </>
    )
  );
};

const ListButtonStep = ({ adminStatus, stepId }) => {
  const { openDialog, dialogClose } = useDialog();
  const { data, loading, call } = Request({
    urlApi: `/Button/processStep/${stepId}`,
  });

  function handleButton() {
    openDialog(
      <CallServiceTable
        urlApi="/Button"
        header={[
          {
            name: "label",
            label: "Etiqueta",
            filter: true,
          },
          {
            name: "add",
            type: "action",
            label: "Adicionar",
            actions: [
              {
                label: "Adicionar",
                icon: "fas fa-plus-circle",
                action: addButton,
                color: "text-[#1d4ed8]",
              },
            ],
          },
        ]}
      />
    );
  }

  async function addButton(button) {
    openDialog(<Circle />);
    await requestAuth("post", `/Button/${button.id}/processStep/${stepId}`)
      .then(() => {
        call();
        dialogClose();
        toast.success("Se adiciono con éxito");
      })
      .catch((response) => {
        dialogClose();
        toast.error(
          response.response.data
            ? response.response.data
            : "Se produjo un error al adicionar"
        );
      });
  }

  function deleteButton(button) {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres eliminar el botón del paso?</H3>
        }
        onClose={dialogClose}
        method="delete"
        url={`/Button/${button.name}/processStep/${stepId}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <H2 className="mb-2">Botones del paso</H2>
        {adminStatus && (
          <div className="flex gap-2">
            <Button
              className="py-1.5 px-2 text-white"
              content="Crear nuevo campo"
              onClick={() => handleButton("create", null)}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </div>
        )}
      </div>
      <div className="flex items-center gap-2 flex-wrap">
        {validateArray(data)
          ? data.map((button) => (
            <div
              key={button.name}
              className="flex items-center text-red-500 gap-2 px-3 py-1 rounded-full bg-slate-300"
              onClick={() => deleteButton(button)}
            >
              <h3 className="m-0 text-black">{button.label}</h3>
              <i className="cursor-pointer fas fa-times"></i>
            </div>
          ))
          : null}
      </div>
    </div>
  );
};

export default ListFields;
